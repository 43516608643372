import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import moment from 'moment-timezone';
import {
  useMutation,
  useQuery,
  useInfiniteQuery,
  useQueryClient,
} from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';

import {
  Badge,
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { VscFilter } from 'react-icons/vsc';

import fetchTasks from 'src/api/tasks/fetchTasks';
import updatePriority from 'src/api/tasks/updatePriority';
import markTaskAsDone from 'src/api/tasks/markTaskAsDone';
import fetchFilteredTasks from 'src/api/tasks/fetchFilteredTasks';
import { fetchSalesmenNames } from 'src/api/salesman/fetchSalesmans';

import services from './services';
// import Filters from './Filters';

import Layout from 'src/layouts';
import palette from 'src/theme/palette';
import ShownTask from 'src/components/tasks/Task';
import TaskList from 'src/components/tasks/TaskList';
import Spinner from 'src/components/lotties/Spinner';

const Filters = React.lazy(() => import('./Filters'));

const TasksPage = () => {
  const {
    location: { state: filterParams = {} },
  } = useHistory();
  // useEffect(() => console.log(filterParams), []);

  const [state, setState] = useState({
    numberOfTasks: 0,
    tasks: [],
    shownTask: null,

    filter: {
      tasks: null,
      numberOfTasks: null,
      shown: false,
      appliedFilters: 0,
      params: {
        taskStatus: 1,
        fromDate: '',
        untilDate: moment().format('yyyy-MM-DD'),

        salesmen: [],
        customers: [],

        salesmenSearchValue: '',
        customersSearchValue: '',

        ...filterParams,
      },
    },
  });

  const isMounted = useRef(false);
  const queryClient = useQueryClient();
  const { ref, inView } = useInView({ threshold: 0.5 });
  const { mutateAsync: mutateTaskStatus } = useMutation(markTaskAsDone);
  const { mutateAsync: mutateTaskPriority } = useMutation(updatePriority);

  const {
    // concatDataPages,
    formatFilterParameters,
    formatResults,
    toggleTask,
    // updateFilterParams,
    updateAppliedFilters,
    updateFilteredTasks,
    updateTaskStatus,
    updateTaskPriority,
    updateTasks,

    filterHandlers,
  } = services(setState, mutateTaskStatus, mutateTaskPriority);

  const { status, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery('tasks', fetchTasks, {
      refetchOnWindowFocus: false,
      enabled: state.filter.tasks === null,
      getNextPageParam: (lastPage) => {
        return lastPage.nextId ?? false;
      },
      select: ({ pages }) => {
        const tasks = formatResults(pages[pages.length - 1].tasks);
        return tasks;
      },
      onSuccess: (tasks) => {
        updateTasks(tasks);
      },
    });

  const { refetch: filterTasks, isFetching: isFiltering } = useQuery(
    'filtered-tasks',
    () => {
      if (isMounted.current || Object.values(filterParams).length !== 0) {
        setState((state) => ({
          ...state,
          filter: { ...state.filter, shown: false },
        }));
        return fetchFilteredTasks(formatFilterParameters(state.filter.params));
      }
    },
    {
      select: (data) => {
        if (data && data.length !== 0 && data.length !== undefined) {
          return formatResults(data);
        } else return data;
      },
      onSuccess: (data) => {
        if (data) {
          queryClient.removeQueries('tasks', { exact: true });
          updateAppliedFilters();
          updateFilteredTasks(data);
        }
      },
      refetchOnWindowFocus: false,
    },
  );

  const { data: salesmenOptions } = useQuery(
    'salesmen-select',
    fetchSalesmenNames,
    {
      refetchOnWindowFocus: false,
      initialData: [],
    },
  );

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage && !isFetching)
      fetchNextPage();
  }, [inView]);

  useEffect(() => {
    isMounted.current = true;
    window.scrollTo({ top: 0, behavior: 'auto' });

    return () => {
      queryClient.removeQueries('tasks', { exact: true });
      queryClient.removeQueries('filtered-tasks', { exact: true });
      queryClient.removeQueries('salesmen-select', { exact: true });
    };
  }, []);

  return (
    <Layout display="flex" justifyContent="space-between">
      <ShownTask
        open={state.shownTask !== null}
        close={() => toggleTask(null)}
        updateStatus={(taskToUpdate) =>
          updateTaskStatus(taskToUpdate, state.filter.tasks !== null)
        }
        updatePriority={(taskToUpdate) =>
          updateTaskPriority(taskToUpdate, state.filter.tasks !== null)
        }
        {...state.shownTask}
      />

      <Filters
        open={state.filter.shown}
        close={() =>
          setState((state) => ({
            ...state,
            filter: { ...state.filter, shown: !state.filter.shown },
          }))
        }
        salesmenOptions={salesmenOptions}
        params={state.filter.params}
        handlers={{ ...filterHandlers, filterTasks }}
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="space-between "
            alignContent="flex-start"
          >
            <Box>
              <Typography variant="h3">Radni zadaci</Typography>
              <Typography variant="subtitle2">
                Broj taskova:{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {state.filter.numberOfTasks !== null &&
                    `${state.filter.numberOfTasks}`}{' '}
                  {state.filter.numberOfTasks === null &&
                    `${state.numberOfTasks || 0}`}
                </span>
              </Typography>
            </Box>

            <Box mt="-1rem" mr="-1.25rem">
              <IconButton
                onClick={() =>
                  setState((state) => ({
                    ...state,
                    filter: { ...state.filter, shown: !state.filter.shown },
                  }))
                }
              >
                <Badge
                  color="secondary"
                  badgeContent={state.filter.appliedFilters}
                >
                  <VscFilter size={30} color={palette.secondary.light} />
                </Badge>
              </IconButton>
            </Box>
          </Box>
          <Box width={1} mt={2} display="flex">
            <Button
              variant="outlined"
              component={RouterLink}
              to="/create-task"
              size="large"
            >
              Kreiraj novi zadatak
            </Button>
          </Box>
        </Grid>

        {(status === 'loading' || isFiltering) && (
          <Grid item xs={12}>
            <Spinner />
          </Grid>
        )}

        {status !== 'loading' && !isFiltering && (
          <>
            {state.filter.tasks === null && !isFiltering && (
              <>
                <Grid item xs={12}>
                  {status === 'success' && state.tasks.length !== 0 && (
                    <TaskList
                      tasks={state.tasks}
                      toggleTask={toggleTask}
                      updateStatus={updateTaskStatus}
                    />
                  )}
                </Grid>

                {hasNextPage && !isFetching && !isFetchingNextPage && (
                  <Grid item xs={12}>
                    <Box p={10}>
                      {!isFetching && !isFetchingNextPage && <div ref={ref} />}
                      {isFetchingNextPage && <Spinner />}
                    </Box>
                  </Grid>
                )}
              </>
            )}

            {state.filter.tasks && (
              <Grid item xs={12}>
                <TaskList
                  tasks={state.filter.tasks}
                  toggleTask={toggleTask}
                  updateStatus={(taskToUpdate) =>
                    updateTaskStatus(taskToUpdate, true)
                  }
                />
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Layout>
  );
};

export default TasksPage;
