import axios from 'axios';
import firebase from 'firebase/app';

const appendAuthToken = async (config) => {
  const token = await firebase.auth()?.currentUser?.getIdToken();
  if (token) config.headers.authorization = token;
  return config;
};

const handleError = (error) => {
  return Promise.reject(error);
};

const fetcher = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3000/api/v1',
});
fetcher.interceptors.request.use(appendAuthToken, handleError);

export default fetcher;
