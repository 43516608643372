import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 100vh;
`;

export const Wrapper = styled.div`
  ${({ theme, offset }) => `
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  padding-top: ${offset ? theme.topBarHeight : 0}px;
  `}
`;

export const ContentContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
`;

export const Content = styled.div`
  flex: 1 1 auto;
  height: 100%;
  padding: 24px 40px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 32px 24px;
  }
`;
