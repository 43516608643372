import fetcher from '../fetcher';

export const fetchSalesmenNames = async () => {
  try {
    const { status, data } = await fetcher.get('/salesman/names');

    if (status === 200) return data.salesmen;
    else return [];
  } catch (error) {
    return [];
  }
};

const fetchSalesmans = async (searchValue = '') => {
  const { data } = await fetcher.get('/salesman/', { params: { searchValue } });
  return data;
};

export default fetchSalesmans;
