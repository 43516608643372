import React from 'react';
import styled from 'styled-components';
import { AppBar as MuiAppBar, Toolbar as MuiToolbar, Box } from '@material-ui/core';
import Account from './Account';
import Notifications from './Notifications';
import { FullLogo } from 'src/components/Logo';

const AppBar = styled(MuiAppBar)`
  z-index: 100;
  box-shadow: none;
  background: transparent;
`;

const Toolbar = styled(MuiToolbar)`
  min-height: ${({ theme }) => theme.topBarHeight}px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

function TopBar({ className, onMobileNavOpen, ...rest }) {
  return (
    <AppBar position="absolute" {...rest}>
      <Toolbar>
        <FullLogo width={40} />
        <Box ml={2} mt={2} flexGrow={1} />
        <Notifications />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
