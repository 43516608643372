import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from '@material-ui/core';
import logoAlt from '../images/logo.png';
import logo from '../images/logo.png';

const StyledLogo = styled.img`
  width: 48px;
  margin: 0 8px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin: 0;
    margin-top: -2px;
    width: 75px;
  }
`;

const Logo = ({ ...rest }) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  return <StyledLogo alt="logo" src={matches ? logoAlt : logo} {...rest} />;
};

export const FullLogo = ({ width, ...rest }) => {
  return <img alt="logo" src={logo} {...rest} width={width || 255} />;
};

export default Logo;
