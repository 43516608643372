import React from 'react';
import styled from 'styled-components';
import { NavLink as RouterLink } from 'react-router-dom';
import { Box, Button as MuiButton } from '@material-ui/core';

const Button = styled(MuiButton)`
  ${({ theme }) => `
  color: #ADADAD;
  text-transform: none;
  justify-content: center;
  letter-spacing: 0;
  min-width: unset;
  padding: 8px;
  font-weight: 400;
  font-size: 0.585rem;
  & svg {
    font-size: 1.45rem;
    margin-bottom: 2px;
  }

  &&.active {
    color: ${theme.palette.text.primary};
    & svg {
      font-size: 1.55rem;
      color: ${theme.palette.primary.main};
    }
  }

  ${theme.breakpoints.up('md')} {
    /* padding: 8px 24px; */
  }

  `}
`;

const NavItem = ({ title, href, icon: Icon }) => {
  return (
    <Button activeClassName="active" component={RouterLink} to={href}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Icon />
        {title}
      </Box>
    </Button>
  );
};

export default NavItem;
