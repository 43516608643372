import React from 'react';
import { useQuery } from 'react-query';
import getTaskImages from '../../../api/document/getTaskImages';
import Spinner from 'src/components/lotties/Spinner';
import Empty from 'src/components/lotties/Empty';
import ServerError from 'src/components/lotties/ServerError';
import { Box, Grid } from '@material-ui/core';
import Upload from '../../Upload';
import Thumbnail from '../../Thumbnail';
import moment from 'moment-timezone';

const ImagesTab = ({ taskId }) => {
  const { data, error, refetch, isLoading } = useQuery(`Images-${taskId}`, () => getTaskImages(taskId), {
    refetchOnWindowFocus: false,
  });
  if (isLoading) return <Spinner />;

  if (error) return <ServerError error={error} />;

  return (
    <>
      <Box mt={1}>
        <Upload taskId={taskId} refetch={refetch} />
      </Box>
      <Grid item container alignItems="center" spacing={2}>
        {data && !data.length ? (
          <Empty title="Nema slika" subtitle="Trenutno niti jedna slika nije spašena" />
        ) : (
          data.map(({ filename, createdAt }) => (
            <Grid item xs={6}>
              <Thumbnail
                imageSrc={`${process.env.REACT_APP_UPLOAD_ENDPOINT}/${filename}`}
                filename={moment(createdAt).format('DD-MM-YY HH:MM')}
              />
            </Grid>
          ))
        )}
      </Grid>
    </>
  );
};

export default ImagesTab;
