import React from 'react';
import { Box, Typography } from '@material-ui/core';
import CheckboxListNew from './CheckboxListNew';
import Empty from './lotties/Empty';

const NestedCheckboxListNew = ({ data, title, checkedItems, checkItem, checkAll }) => {
  const count = Object.values(data || {}).reduce((a, b) => a + b.length, 0) || 0;

  if (count === 0) return <Empty title="Nema rezultata" />;

  const checkedCount =
    Object.values(checkedItems).reduce(
      (checkedCount, superior) => (checkedCount += Object.values(superior).length),
      0
    ) || 0;

  NestedCheckboxListNew.checkItem = ({ name, checked }) => checkItem({ name, checked });
  NestedCheckboxListNew.checkAll = (allChecked, title) => checkAll(allChecked, title);

  return (
    <Box mt={3} mb={6}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">
          {title} ({count})
        </Typography>{' '}
        <Typography variant="overline" color={checkedCount !== 0 ? 'secondary' : 'textSecondary'}>
          Odabrano {checkedCount}
        </Typography>
      </Box>
      {Object.entries(data || {}).map(([title, items]) => {
        return (
          <CheckboxListNew
            key={title}
            isShown={count <= 250}
            title={`${title}`}
            data={items}
            checkedItems={checkedItems[title] || {}}
            checkItem={({ target: { name, checked } }) =>
              NestedCheckboxListNew.checkItem({ name: `${title}->${name}`, checked })
            }
            checkAll={(allChecked) => NestedCheckboxListNew.checkAll(allChecked, title)}
          />
        );
      })}
    </Box>
  );
};

export default NestedCheckboxListNew;
