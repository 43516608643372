import React from 'react';

//  ROK / BREND - ARTIKAL                     KOMENTARI / SLIKE

// DATUM U NASLOVU H4

import styled, { css } from 'styled-components';

import { Box, Typography } from '@material-ui/core';

import palette from 'src/theme/palette';
import { FaCheck } from 'react-icons/fa';
import TaskIconRow from './TaskIconRow';

const CheckButton = styled.div`
  cursor: pointer;
  padding: 3px;
  margin-right: 1rem;

  box-sizing: border-box;

  border-radius: 50px;
  border: 1px solid;
  border-color: ${({ theme, checked }) =>
    checked ? theme.palette.success.main : theme.palette.grey[400]};
  display: flex;
  height: 20px;
  width: 20px;
`;

const CheckBoxButton = ({ checked = false, onClick = () => null }) => {
  return (
    <CheckButton onClick={onClick} checked={checked}>
      {checked === true && <FaCheck size={12} color={palette.success.main} />}
    </CheckButton>
  );
};

const SimpleTaskViewContainer = styled.div`
  cursor: pointer;
  flex: 1 100%;

  ${(boundHeight) =>
    boundHeight
      ? css`
          box-sizing: border-box;
          height: auto;
          max-height: 200px;

          & .MuiTypography-body1 {
            overflow: auto;
          }
        `
      : null}

  display: flex;
  align-content: flex-start;
  border-bottom: 1px solid;
  border-color: #eee;

  padding: 8px 0;

  & .MuiTypography-body1 {
    line-height: 1.33;
    color: #202020;
    font-weight: 400;
  }
  & .MuiAvatar-root {
    width: 16px;
    height: 16px;
  }
`;

const isEqaul = (prevProps, props) => {
  return (
    prevProps.status === props.status && prevProps.priority === props.priority
  );
};
const SimpleTaskView = ({
  id,
  name,
  dueDate,
  customer,
  boundHeight,
  status = false,
  groupIndex = 0,
  salesmenIndex = 0,
  taskIndex = 0,
  priority = 'N',
  listed = 'N',
  articleCount = 0,
  commentCount = 0,
  imageCount = 0,
  openTask = () => null,
  updateStatus = () => null,
}) => {
  return (
    <SimpleTaskViewContainer boundHeight={boundHeight}>
      <CheckBoxButton
        checked={!!status}
        onClick={() =>
          updateStatus({
            id,
            groupIndex,
            salesmenIndex,
            taskIndex,
            status: !status,
          })
        }
      />

      <Box flex={1} onClick={openTask}>
        <Typography variant="body1">{name}</Typography>

        <Typography variant="subtitle1" gutterBottom>
          {customer}
        </Typography>

        <TaskIconRow
          dueDate={dueDate}
          articleCount={articleCount}
          commentCount={commentCount}
          imageCount={imageCount}
          priority={priority}
          listed={listed}
        />
      </Box>
    </SimpleTaskViewContainer>
  );
};

// export default SimpleTaskView;
export default React.memo(SimpleTaskView, isEqaul);
