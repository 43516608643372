import fetcher from 'src/api/fetcher';

const getTaskImages = async (task) => {
  if (!task) return { data: [] };
  try {
    const response = await fetcher.get('/documents', {
      params: { task },
    });

    if (response.status === 200) return response.data;
    else return { data: [] };
  } catch (error) {
    console.log(error.response?.data.message || error.message || 'Greška');
    throw error;
  }
};

export default getTaskImages;
