import React from 'react';
import styled from 'styled-components';
import { FormProvider, useFormContext } from 'react-hook-form';
import { Grid } from '@material-ui/core';

export const StyledForm = styled.form`
  ${({ theme }) => `
  .Mui-error {
      color: ${theme.palette.text.secondary};
   }

  .MuiFormHelperText-root.Mui-error {
     color: ${theme.palette.error.main};
     font-weight: 500;
  }

  .MuiInput-underline:before {
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid #e6e6e6;
  }

  .MuiInput-underline:after {
    transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid #39A0ED;
  }

`}
`;

const commonProps = (name, { control, errors = {} }) => {
  return {
    key: name,
    control,
    error: !!errors[name],
    helperText: errors[name]?.message,
    fullWidth: true,
  };
};

export const SmartFormConnect = ({ children }) => {
  const methods = useFormContext();
  return React.Children.map(children, (child) => {
    return child?.props.name
      ? React.createElement(child.type, {
          ...{
            ...child.props,
            ...commonProps(child.props.name, methods),
          },
        })
      : child;
  });
};

const SmartForm = ({ methods, onSubmit, spacing = 3, inputProps = {}, children, ...rest }) => {
  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate {...rest}>
        <Grid container spacing={spacing}>
          {React.Children.map(children, (child) => {
            return (
              <>
                {child?.props.name ? (
                  <Grid item xs={child.props?.xs || 12} md={child.props?.md || 12} lg={child.props?.lg}>
                    {React.createElement(child.type, {
                      ...{
                        ...child.props,
                        ...commonProps(child.props.name, methods),
                        ...inputProps,
                      },
                    })}
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    {React.createElement(child.type, {
                      ...{
                        ...child.props,
                        control: methods.control,
                      },
                    })}
                  </Grid>
                )}
              </>
            );
          })}
        </Grid>
      </StyledForm>
    </FormProvider>
  );
};

export default SmartForm;
