import fetcher from 'src/api/fetcher';

const getProductsBrends = async (taskId) => {
  if (!taskId) return { items: [] };
  try {
    const response = await fetcher.get('/tasks/products-brends', {
      params: { taskId },
    });

    if (response.status === 200) return response.data;
    else return { items: [] };
  } catch (error) {
    console.log(error.response?.data.message || error.message || 'Greška');
    throw error;
  }
};

export default getProductsBrends;
