import React, { useState } from 'react';

import { ResponsiveBar } from '@nivo/bar';
import { Box, Typography, useMediaQuery } from '@material-ui/core';

import { useHistory } from 'react-router-dom';

const getStyles = (isSmUp) => {
  if (isSmUp)
    return {
      legends: {
        top: {
          translateX: -25,
        },
      },
    };
  else
    return {
      legends: {
        top: {
          translateX: -32,
        },
      },
    };
};

const BarTooltip = ({ color, id, value, data: { salesman } }) => (
  <Box
    borderRadius={8}
    color={color}
    fontFamily={['Inter', 'sans-serif'].join(',')}
    bgcolor="#222"
    p="14px"
  >
    <Typography variant="caption" color="inherit" gutterBottom>
      Komercijalista:&nbsp;
      <strong>{salesman}</strong>
    </Typography>
    <br />
    <Typography variant="caption" color="inherit">
      {id}:&nbsp; <span style={{ fontWeight: 'bold' }}>{value}</span>
    </Typography>
  </Box>
);

const getStatus = (status) => {
  if (status === 'Urađeni zadaci') return 2;
  else if (status === 'Zadaci u izradi') return 3;
  else if (status === 'Neurađeni zadaci') return 4;

  return 1;
};
const MyBarChart = ({ data }) => {
  const history = useHistory();
  const isSmUp = useMediaQuery(({ breakpoints }) => breakpoints.up('sm'));
  const {
    legends: { top },
  } = getStyles(isSmUp);

  const [clickedDatum, setClickedDatum] = useState(null);

  const handleDatumClick = (datum) => {
    if (
      clickedDatum?.data?.salesman !== datum.data.salesman ||
      clickedDatum?.id !== datum.id
    ) {
      setClickedDatum(datum);
      setTimeout(() => setClickedDatum(null), 750);
    } else
      history.push('/tasks', {
        salesmen: [{ label: datum.data.salesman, value: datum.data.id }],
        taskStatus: getStatus(datum.id),
      });
  };

  return (
    <ResponsiveBar
      data={data}
      keys={['Urađeni zadaci', 'Zadaci u izradi', 'Neurađeni zadaci']}
      indexBy="initials"
      margin={{ top: 80, right: 10, bottom: 120, left: 75 }}
      padding={0.25}
      layout="horizontal"
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={({ id, data }) =>
        String(data[`${id.toLowerCase().replace(/ /g, '')}Color`])
      }
      borderRadius={1}
      borderColor={{ from: 'color', modifiers: [['darker', '0']] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Radni Zadaci',
        legendPosition: 'middle',
        legendOffset: 40,
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 10,
        tickRotation: 0,
        //   legend: 'Komercijaliste',
        //   legendPosition: 'top',
        //   legendOffset: -0,
      }}
      enableGridX={true}
      enableGridY={false}
      labelSkipWidth={16}
      labelSkipHeight={10}
      // labelTextColor={{ from: 'color', modifiers: [['brighter', 10]] }}
      labelTextColor="#fff"
      legends={[
        {
          ...top,
          dataFrom: 'keys',
          anchor: 'top',
          direction: 'row',
          justify: false,
          translateY: -52,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 18,
          itemDirection: 'top-to-bottom',
          symbolSize: 18,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      tooltip={(datum) => <BarTooltip {...datum} />}
      theme={{
        labels: {
          text: {
            fontWeight: 'bold',
            fontSize: '0.865rem',
          },
        },
      }}
      onClick={handleDatumClick}
    />
  );
};
export default MyBarChart;
