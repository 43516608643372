import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Box, Typography, Button } from '@material-ui/core';
import Layout from '../layouts';
import Lottie from '../components/lotties/Lottie';
import notFoundData from '../components/lotties/animationData/notFound.json';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 11vh;
  && > div {
    margin: auto;
    min-width: 38vw;
    height: 100%;
    width: 100%;
  }
`;

const NotFound = () => {
  return (
    <Layout>
      <Container>
        <Lottie animationData={notFoundData} animate={true} />
        <Box textAlign="center">
          <Typography variant="h4" gutterBottom>
            Stranica nije pronađena
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            Stranica ne postoji ili se nalazi na drugoj putanji
          </Typography>
          <Box mt={2}>
            <Button variant="contained" color="secondary" disableElevation component={Link} to="/">
              Nazad
            </Button>
          </Box>
        </Box>
      </Container>
    </Layout>
  );
};

export default NotFound;
