import MuiButton from './MuiButton';
import MuiLink from './MuiLink';
import MuiFormControlLabel from './MuiFormControlLabel';
import MuiInputLabel from './MuiInputLabel';
import MuiSelect from './MuiSelect';
import MuiCheckbox from './MuiCheckbox';
import MuiTextField from './MuiTextField';

const overrides = {
  MuiButton,
  MuiLink,
  MuiFormControlLabel,
  MuiInputLabel,
  MuiSelect,
  MuiCheckbox,
  MuiTextField,
};

export default overrides;
