import palette from './palette';

const typography = {
  fontSize: 16,
  fontFamily: ['Inter', 'sans-serif'].join(','),
  // fontFamily: [
  //   '-apple-system',
  //   'BlinkMacSystemFont',
  //   'Segoe UI',
  //   'Roboto',
  //   'Helvetica,Arial',
  //   'sans-serif',
  //   'Apple Color Emoji',
  //   'Segoe UI Emoji',
  //   'Segoe UI Symbol',
  // ].join(','),
  h1: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '2.5rem',
  },
  h2: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '2rem',
  },
  h3: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '1.75rem',
  },
  h4: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: '1.25rem',
    lineHeight: 1.25,
  },
  h5: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '1rem',
  },
  h6: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: 1.425,
  },
  subtitle1: {
    color: palette.text.secondary,
    fontSize: '0.875rem',
    fontWeight: 400,
  },
  subtitle2: {
    color: palette.text.secondary,
    fontSize: '0.825rem',
    fontWeight: 400,
    lineHeight: 1.35,
  },
  body1: {
    color: palette.text.primary,
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: 1.5,
  },
  body2: {
    color: palette.text.primary,
    fontSize: '0.75rem',
    lineHeight: 1.33,
    fontWeight: 400,
  },
  button: {
    color: palette.text.primary,
    fontSize: '0.75rem',
    fontWeight: 500,
    textTransform: 'none',
  },
  caption: {
    color: palette.text.secondary,
    fontSize: '0.75rem',
  },
  overline: {
    color: palette.text.secondary,
    fontSize: '0.675rem',
    fontWeight: 500,
    letterSpacing: '0.33px',
    lineHeight: '16px',
  },
};

export default typography;
