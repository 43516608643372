/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import useFirebaseAuth from '../state/useFirebaseAuth';

export default function PrivateRoute({ children, ...rest }) {
  const { user, isAuthReady } = useFirebaseAuth();

  if (!user && !isAuthReady) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
