import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { ResponsivePie } from '@nivo/pie';

import { useMediaQuery } from '@material-ui/core';

const getStyles = (isSmUp) => {
  if (isSmUp)
    return {
      margin: { top: 20, right: 45, bottom: 130, left: 45 },
      legends: {
        bottom: {
          translateY: 56,
        },
      },
    };
  else
    return {
      margin: { top: -20, right: 30, bottom: 30, left: 30 },
      legends: {
        bottom: {
          translateY: 0,
        },
      },
    };
};

const CenteredMetric = ({ dataWithArc, centerX, centerY, total }) => {
  return (
    <>
      <text
        x={centerX}
        y={centerY - 8}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: '1.5rem',
          fontWeight: '600',
          fontFamily: ['Inter', 'sans-serif'].join(','),
        }}
      >
        Ukupno:
      </text>
      <text
        x={centerX}
        y={centerY + 24}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: '1.5rem',
          fontWeight: '600',
          fontFamily: ['Inter', 'sans-serif'].join(','),
        }}
      >
        {total}
      </text>
    </>
  );
};

const getStatus = (status) => {
  if (status === 'Urađeni zadaci') return 2;
  else if (status === 'Zadaci u izradi') return 3;
  else if (status === 'Neurađeni zadaci') return 4;

  return 1;
};
const MyPieChart = ({ data: { total, pieChartData } }) => {
  const [canReroute] = useState(pieChartData[0].salesman ? true : false);

  const history = useHistory();

  const isSmUp = useMediaQuery(({ breakpoints }) => breakpoints.up('sm'));
  const {
    margin,
    legends: { bottom },
  } = getStyles(isSmUp);

  const [clickedDatum, setClickedDatum] = useState(null);
  const handleDatumClick = (datum) => {
    if (
      clickedDatum?.data?.salesman !== datum.data.salesman ||
      clickedDatum?.id !== datum.id
    ) {
      setClickedDatum(datum);
      setTimeout(() => setClickedDatum(null), 750);
    } else
      history.push('/tasks', {
        salesman: [
          { label: datum.data.salesman, value: datum.data.salesmanId },
        ],
        taskStatus: getStatus(datum.label),
      });
  };

  return (
    <ResponsivePie
      data={pieChartData}
      colors={(datum) => datum.data.color}
      margin={margin}
      innerRadius={0.65}
      padAngle={1}
      cornerRadius={2}
      activeOuterRadiusOffset={6}
      borderWidth={1}
      borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      // arcLinkLabelsSkipAngle={10}
      // arcLinkLabelsDiagonalLength={10}
      // arcLinkLabelsOffset={-6}
      // arcLinkLabelsTextColor="#444"
      // arcLinkLabelsThickness={3}
      // arcLinkLabelsColor={{ from: 'color' }}
      arcLabelsSkipAngle={10}
      // arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 9]] }}
      arcLabelsTextColor="#fff"
      legends={[
        {
          ...bottom,
          anchor: 'bottom',
          direction: 'row',
          justify: false,
          translateX: 0,
          itemsSpacing: 0,
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: '#444',
          itemDirection: 'top-to-bottom',
          itemOpacity: 1,

          symbolSize: 18,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000',
              },
            },
          ],
        },
      ]}
      layers={[
        'arcs',
        'arcLabels',
        // 'arcLinkLabels',
        'legends',
        (props) => <CenteredMetric {...props} total={total} />,
      ]}
      theme={{
        tooltip: {
          chip: {
            borderRadius: '100px',
          },
        },
        labels: {
          text: {
            fontWeight: 'bold',
            fontSize: '0.865rem',
          },
        },
      }}
      onClick={(datum) => canReroute && handleDatumClick(datum)}
      // arcLabelsComponent={({ datum, label, style }) => (
      //   <animated.g transform={style.transform} style={{ pointerEvents: 'none' }}>
      //     <circle fill={style.textColor} cy={6} r={15} />
      //     <circle fill="#ffffff" stroke={datum.color} strokeWidth={2} r={16} />
      //     <text
      //       textAnchor="middle"
      //       dominantBaseline="central"
      //       fill={style.textColor}
      //       style={{
      //         fontSize: 10,
      //         fontWeight: 800,
      //       }}
      //     >
      //       {label}
      //     </text>
      //   </animated.g>
      // )}
    />
  );
};
export default MyPieChart;
