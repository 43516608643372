import moment from 'moment-timezone';
import fetcher from '../fetcher';

const isFilterDisabled = ({
  taskStatus,
  fromDate,
  untilDate,
  salesmen,
  customers,
}) =>
  taskStatus === 1 &&
  fromDate === null &&
  untilDate === moment().format('yyyy-MM-DD') &&
  salesmen.length === 0 &&
  customers.length === 0;

const fetchFilteredTasks = async (params) => {
  if (isFilterDisabled(params)) return;

  try {
    const {
      status,
      data: { tasks },
    } = await fetcher.get('/tasks/filtered', {
      params,
    });

    if (status === 200) return tasks;
    else return { numberOfTasks: 0, groupedTasks: [] };
  } catch (error) {
    throw error;
  }
};

export default fetchFilteredTasks;
