import fetcher from 'src/api/fetcher';

const updatePriority = async ({ taskId, priority }) => {
  try {
    const response = await fetcher.patch('/tasks/priority', {
      taskId,
      priority,
    });

    return response.status === 204;
  } catch (error) {
    console.log(
      error?.response?.data?.message || error?.message || 'Greška...',
    );
    throw error;
  }
};

export default updatePriority;
