import fetcher from 'src/api/fetcher';

const postComment = async ({ taskId, comment }) => {
  try {
    const response = await fetcher.post('/tasks/comments', {
      taskId,
      comment,
    });

    return response.status === 204;
  } catch (error) {
    throw error;
  }
};

export default postComment;
