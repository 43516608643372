import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Avatar, Box, ButtonBase, Menu, MenuItem } from '@material-ui/core';
import useFirebaseAuth from 'src/state/useFirebaseAuth';
// import useFirebaseAuth from 'src/state/useFirebaseAuth';

const UserAvatar = styled(Avatar)`
  height: 32px;
  width: 32px;
  background: ${({ theme }) => theme.palette.secondary.light};
  box-shadow: 0 1.4px 4.7px rgba(0, 0, 0, 0.028), 0 4px 12.9px rgba(0, 0, 0, 0.04), 0 9.6px 31.1px rgba(0, 0, 0, 0.052),
    0 32px 103px rgba(0, 0, 0, 0.08);
`;

function Account() {
  const ref = useRef(null);
  const { signOut } = useFirebaseAuth();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box display="flex" alignItems="center" component={ButtonBase} onClick={handleOpen} ref={ref}>
        <UserAvatar alt="User">A</UserAvatar>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
        disableScrollLock
      >
        {<MenuItem onClick={() => signOut()}>Logout</MenuItem>}
      </Menu>
    </>
  );
}

export default Account;
