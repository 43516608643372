import fetcher from '../fetcher';

const fetchTaskMetrics = async () => {
  try {
    const { status, data: { metrics } = {} } = await fetcher.get(
      '/tasks/metrics',
    );

    if (status === 200) return metrics;
    else return null;
  } catch (error) {
    throw error;
  }
};

export default fetchTaskMetrics;
