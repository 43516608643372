import React, { useEffect, useState } from 'react';

import styled, { css } from 'styled-components';
import moment from 'moment';
import { useQuery, useMutation } from 'react-query';

import {
  Avatar,
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import { GrAttachment } from 'react-icons/gr';

import postComment from 'src/api/comments/addComment';
import getComments from 'src/api/comments/getComments';
import Spinner from 'src/components/lotties/Spinner';
import ServerError from 'src/components/lotties/ServerError';
import useFirebaseAuth from 'src/state/useFirebaseAuth';

const CommentsTabContainer = styled.div`
  height: 60vh;
  padding-bottom: 8px;
  /* overflow: scroll; */
`;

const CommentContainer = styled.div`
  display: flex;
  /* padding: 1rem 0; */
  margin-bottom: 16px;

  &:first-child {
    padding-top: 16px;
  }

  & .comment-header {
    align-items: center;
    margin-bottom: 8px;
  }

  & .comment-header > .MuiTypography-root {
    flex: 1;
    line-height: 1.35;
  }

  & .comment-header :last-child {
    flex: 2;
  }

  & .MuiIconButton-root {
    padding: 0;
    margin-right: 8px;
  }

  & .MuiAvatar-root {
    font-size: 1rem;

    /* height: 2rem;
    width: 2rem; */
    height: 40px;
    width: 40px;
    text-transform: capitalize;

    ${({ theme }) => css`
      color: ${theme.palette.primary.contrastText};
      background-color: ${theme.palette.primary.main};
    `}
  }
  & .MuiTypography-h6 {
    font-weight: 600;
    font-size: 13px;
    margin-right: 4px;
  }
  & .MuiTypography-caption {
    line-height: 1.7;
    text-transform: capitalize;
  }

  & .comment-options > * {
    flex: 0 !important;
  }
`;

const Comment = ({ createdBy, date, comment }) => {
  return (
    <CommentContainer>
      <Avatar>
        {createdBy
          .split(' ')
          .map((name) => name[0])
          .join('')}
      </Avatar>
      <Box display="flex" flexDirection="column" flex={1} pl={1}>
        <Box display="flex">
          <Typography variant="h6">{createdBy}</Typography>
          <Typography variant="caption">{date}</Typography>
        </Box>
        <Typography variant="body1">{comment}</Typography>
      </Box>
    </CommentContainer>
  );
};

const CommentsTab = ({ taskId, hidden }) => {
  const [state, setState] = useState({
    commentError: false,
    newComment: '',
    comments: [],
  });
  const { user } = useFirebaseAuth();
  const { data, status, error } = useQuery(
    `taskMessages-${taskId}`,
    () => getComments(taskId),
    {
      refetchOnWindowFocus: false,
    },
  );
  const mutation = useMutation(postComment);

  useEffect(() => {
    if (data?.length)
      setState({
        ...state,
        comments: data.map((comment) => ({
          ...comment,
          date: moment(comment.date).format('D MMMM HH:mm'),
        })),
      });
  }, [data]);

  const handleNewCommentUpdate = ({ target: { value } }) =>
    setState({ ...state, newComment: value });
  const addComment = async () => {
    if (state.newComment) {
      const wasAdded = await mutation.mutateAsync({
        taskId,
        comment: state.newComment,
      });
      if (wasAdded) {
        mutation.reset();
        setState({
          commentError: false,
          comments: [
            {
              createdBy: user?.displayName || user?.email || 'Ja',
              date: moment().format('D MMMM HH:mm'),
              comment: state.newComment,
            },
            ...state.comments,
          ],
          newComment: '',
        });
      }
    } else setState({ ...state, commentError: true });
  };

  return (
    <CommentsTabContainer>
      {status === 'loading' && <Spinner />}
      {status === 'error' && <ServerError error={error} />}
      {status === 'success' && (
        <React.Fragment>
          <Box height="34vh" overflow="scroll">
            {state.comments?.map((comment, index) => (
              <Comment key={index} {...comment} />
            ))}
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            border="1px solid"
            borderColor="#eee"
            p={1}
            mt={1}
          >
            <TextField
              fullWidth
              multiline
              value={state.newComment}
              rows={4}
              label="Unesite komentar"
              error={state.commentError}
              onChange={handleNewCommentUpdate}
            />
            <Box display="flex" justifyContent="space-between" mt={1}>
              <IconButton color="primary">
                <GrAttachment size={20} />
              </IconButton>

              <Button color="primary" variant="contained" onClick={addComment}>
                DODAJ KOMENTAR
              </Button>
            </Box>
          </Box>
        </React.Fragment>
      )}
    </CommentsTabContainer>
  );
};

export default CommentsTab;
