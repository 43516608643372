import React from 'react';
import { Box, Typography, List, ListItem } from '@material-ui/core';

import SimpleTaskView from 'src/components/tasks/SimpleTaskView';
import Empty from '../lotties/Empty';

const TaskListItem = ({
  startDate,
  salesmen,
  updateStatus,
  toggleTask,
  groupIndex,
}) => (
  <Box display="flex" flexDirection="column" mb={3}>
    <Box
      display="flex"
      borderBottom="1px solid #eee"
      pb={1}
      // mb={2}
      alignContent="flex-bottom"
    >
      <Typography
        className="start-date-header"
        variant="h4"
        style={{ textTransform: 'capitalize' }}
      >
        {startDate}
      </Typography>
    </Box>

    {salesmen.map(({ salesman, tasks }, salesmenIndex) => (
      <Box key={salesman + startDate} mt={2}>
        {salesman !== 'undefined' && (
          <Typography variant="h5" className="salesman" gutterBottom>
            {salesman}
          </Typography>
        )}
        <List dense disablePadding>
          {tasks.map((task, taskIndex) => {
            return (
              <ListItem key={task.id} button style={{ padding: 0 }}>
                <SimpleTaskView
                  openTask={() =>
                    toggleTask({
                      ...task,
                      groupIndex,
                      salesmenIndex,
                      taskIndex,
                    })
                  }
                  updateStatus={updateStatus}
                  groupIndex={groupIndex}
                  salesmenIndex={salesmenIndex}
                  taskIndex={taskIndex}
                  {...task}
                />
              </ListItem>
            );
          })}
        </List>
      </Box>
    ))}
  </Box>
);

const TaskList = ({ tasks = [], toggleTask, updateStatus = () => null }) => {
  if (tasks.length === 0) return <Empty title="Nema taskova" />;

  return tasks.map(({ startDate, salesmen }, groupIndex) => (
    <TaskListItem
      key={groupIndex + startDate}
      startDate={startDate}
      salesmen={salesmen}
      groupIndex={groupIndex}
      updateStatus={updateStatus}
      toggleTask={toggleTask}
    />
  ));
};

export default TaskList;
