import React, { useState } from 'react';

import { useQuery } from 'react-query';
import getProductsBrends from 'src/api/brand/product-brend/getProductsBrends';
import Spinner from 'src/components/lotties/Spinner';
import ServerError from 'src/components/lotties/ServerError';
import Empty from 'src/components/lotties/Empty';

import NestedCheckboxListNew from 'src/components/NestedCheckboxListNew';
import CheckboxListNew from 'src/components/CheckboxListNew';
import { StyledForm } from 'src/components/rhf/SmartForm';

const Brands = ({ data }) => {
  const [state, setState] = useState({
    brands: {},
  });

  const checkItem = ({ target: { name, checked } }) => {
    const brands = { ...state.brands };

    if (checked) brands[name] = '';
    else delete brands[name];

    setState({ brands });
  };
  const checkAll = (allChecked) => {
    const brands = allChecked
      ? data.reduce((brands, { id }) => {
          brands[id] = '';
          return brands;
        }, {})
      : {};

    setState({ brands });
  };

  return (
    <StyledForm>
      <CheckboxListNew
        title="Brendovi"
        data={data}
        checkedItems={state.brands}
        checkItem={checkItem}
        checkAll={checkAll}
        isShown={true}
      />
    </StyledForm>
  );
};

const Products = ({ data }) => {
  const [state, setState] = useState({
    products: {},
  });

  const checkItem = ({ name, checked }) => {
    const products = Object.entries(state.products).reduce((products, [key, value]) => {
      products[key] = { ...value };
      return products;
    }, {});
    const [superior, subordinate] = name.split('->');

    if (checked) {
      if (products[superior] !== undefined) products[superior] = { ...state.products[superior], [subordinate]: '' };
      else products[superior] = { [subordinate]: '' };
    } else if (!checked) {
      delete products[superior][subordinate];

      if (Object.keys(products[superior]).length === 0) delete products[superior];
    }

    setState({ ...state, products });
  };
  const checkAll = (allChecked, superior) => {
    if (allChecked) {
      const products = data[superior].reduce((acc, { id }) => (acc = { ...acc, [id]: '' }), {});
      setState({ ...state, products: { ...state.products, [superior]: products } });
    } else {
      const products = state.products;
      delete products[superior];
      setState({ ...state, products });
    }
  };

  return (
    <StyledForm>
      <NestedCheckboxListNew
        data={data}
        title="Artikli"
        checkedItems={state.products}
        checkItem={checkItem}
        checkAll={checkAll}
      />
    </StyledForm>
  );
};

const ProductBrendTab = ({ taskId }) => {
  const { data, status, error } = useQuery(`productsBrends-${taskId}`, () => getProductsBrends(taskId), {
    refetchOnWindowFocus: false,
  });

  return (
    <div>
      {status === 'loading' && <Spinner />}
      {status === 'error' && <ServerError error={error} />}
      {status === 'success' && (
        <React.Fragment>
          {data?.items?.length === 0 && (
            <Empty title="Prazno" subtitle="Za task nisu vezani niti artikli niti brendovi" />
          )}

          {data?.productBrendInfo === 'Artikal' && <Products data={data.items} />}
          {data?.productBrendInfo === 'Brend' && <Brands data={data.items} />}
        </React.Fragment>
      )}
    </div>
  );
};

export default ProductBrendTab;
