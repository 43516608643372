import React from 'react';
import styled from 'styled-components';
import { Box, Typography } from '@material-ui/core';
import Lottie from './Lottie';
import placeholderData from './animationData/placeholder.json';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  && > div {
    max-width: 80% !important;
    margin: -16px auto;
  }
`;

const Empty = ({ title, subtitle }) => {
  return (
    <Container>
      <Lottie animationData={placeholderData} animate={true} />
      <Box textAlign="center">
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Typography variant="subtitle2">{subtitle}</Typography>
      </Box>
    </Container>
  );
};

export default Empty;
