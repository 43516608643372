import { transparentize } from 'polished';

const white = '#FFFFFF';
const black = '#000000';

const palette = {
  black,
  white,
  primary: {
    light: '#C4D7F2',
    main: '#3A62A6',
    dark: '#483AA6',
    contrastText: white,
  },
  secondary: {
    light: '#218380',
    main: '#ED733E',
    dark: '#633e54',
    contrastText: white,
  },
  error: {
    contrastText: white,
    main: '#ff4242',
  },
  warning: {
    contrastText: white,
    main: '#FFCA3A',
    dark: '#fa8e00',
  },
  info: {
    contrastText: white,
    main: '#2D8FD5',
  },
  success: {
    contrastText: white,
    main: '#66B821',
  },
  text: {
    primary: '#02020A',
    secondary: transparentize(0.4, '#02020A'),
    placeholder: transparentize(0.62, '#02020A'),
    link: '#2D8FD5',
  },
  // divider: {
  //   primary: '#ddd',
  // },
  background: {
    default: '#f8fafd',
    paper: white,
    block: '#f9fafb',
    gray: '#687792',
  },
  navbarColor: '',
};

export default palette;
