import React from 'react';
import { Switch, Typography, Box } from '@material-ui/core';

const LockTask = ({ priority, updatePriority }) => {
  const checked = priority === 'D';

  // const [checked, setChecked] = useState(priority === 'D');
  // const queryClient = useQueryClient();
  // const { mutate } = useMutation(updatePriority, {
  //   onSuccess: () => {
  //     queryClient.invalidateQueries('tasks');
  //     setChecked(!checked);
  //     window.location.reload();
  //   },
  // });

  // const onChange = async () => {
  //   mutate({ taskId, priority: !checked ? 'D' : 'N' });
  // };

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end">
      <Typography variant="caption">Narudžbe zaključane:</Typography>
      <Switch
        checked={checked}
        style={!checked ? { color: '#ddd' } : {}}
        onChange={updatePriority}
      />{' '}
    </Box>
  );
};

export default LockTask;
