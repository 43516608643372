import fetcher from 'src/api/fetcher';

const getComments = async (taskId) => {
  try {
    const response = await fetcher.get('/tasks/comments', {
      params: { taskId },
    });

    if (response.status === 200) return response.data;
    else if (response.status === 204) return [];
  } catch (error) {
    throw error;
  }
};

export default getComments;
