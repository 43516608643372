import fetcher from 'src/api/fetcher';

const markTaskAsDone = async ({ taskId, status }) => {
  try {
    const response = await fetcher.patch('/tasks/status', {
      taskId,
      status,
    });

    return response.status === 204;
  } catch (error) {
    console.log(error?.response?.data?.message || error?.message || 'Greška...');
    throw error;
  }
};

export default markTaskAsDone;
