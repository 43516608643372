import React, { useEffect, createRef } from 'react';
import lottie from 'lottie-web';

const Lottie = ({ animationData, delay = 0, autoplay = true, animate = true, loop = true, maxWidth = 150 }) => {
  const animationContainer = createRef();

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop,
      autoplay,
      animationData,
    });

    let timer;
    if (animate) {
      timer = setTimeout(() => {
        anim.play();
      }, delay);
    }

    return () => {
      if (timer) clearTimeout(timer);
      anim.destroy();
    };
  }, [animate, animationContainer, animationData, autoplay, delay, loop]);

  return (
    <div className="lottie" style={{ maxWidth }}>
      <div className="animation-container" ref={animationContainer} />
    </div>
  );
};

export default Lottie;
