import React from 'react';
import styled from 'styled-components';
import { FormHelperText } from '@material-ui/core';
import Dropzone from './Dropzone';

const ErrorText = styled(FormHelperText)`
  margin-top: -50px;
  margin-left: 24px;
`;

const Upload = ({ helperText, refetch, taskId }) => {
  return (
    <>
      <Dropzone files={[]} onChange={(files) => refetch()} taskId={taskId} />

      {helperText && <ErrorText error>{helperText}</ErrorText>}
    </>
  );
};
export default Upload;
