import moment from 'moment';
import groupBy from 'src/utils/groupBy';
import fetcher from '../fetcher';

const groupTasksByDateAndSalesman = (tasks) => {
  const groupedByCreateDate = groupBy(tasks, 'startDate');

  const groupedByCreateDateAndSalesman = Object.entries(
    groupedByCreateDate,
  ).map(([date, tasks]) => ({
    startDate: date,
    salesmen: Object.entries(groupBy(tasks, 'salesman')).map(
      ([salesman, tasks]) => ({
        salesman,
        tasks, //: tasks.map(({ id, name, customer, dueDate }) => ({ id, name, customer, dueDate })),
      }),
    ),
  }));

  return groupedByCreateDateAndSalesman;
};

const fetchTasks = async ({ pageParam = 0 }) => {
  const kraj = moment().subtract(pageParam, 'd').format('yyyy-MM-DD');
  const pocetak = moment(kraj).subtract(7, 'd').format('yyyy-MM-DD');

  try {
    const { status, data } = await fetcher.get('/tasks', {
      params: {
        condition: {
          datumOdPocetak: pocetak,
          datumOdKraj: kraj,
        },
      },
    });

    if (status === 200) {
      const { tasks, nextDate } = data;

      const dateDifference = nextDate
        ? moment(kraj).diff(moment(nextDate), 'd')
        : null;

      console.log(nextDate, dateDifference);

      return {
        tasks,
        nextId: nextDate ? pageParam + dateDifference : null,
      };
    }
  } catch (error) {
    console.log(error?.response?.data?.message || error || 'Greška...');
    throw error;
  }
};

export const fetchTasksNew = async ({ pageParam = 0 }) => {
  const pocetak = moment()
    .subtract(pageParam * 7, 'd')
    .format('yyyy-MM-DD');
  const kraj = moment(pocetak).subtract(7, 'd').format('yyyy-MM-DD');

  try {
    const response = await fetcher.get('/tasks', {
      params: {
        condition: {
          datumOdPocetak: pocetak,
          datumOdKraj: kraj,
        },
      },
    });

    if (response.status === 200) {
      const { tasks, nextDate } = response.data;
      const groupedTasks = groupTasksByDateAndSalesman(tasks);

      const dateDifference = nextDate
        ? moment(pocetak).diff(moment(nextDate), 'weeks')
        : null;

      return {
        groupedTasks,
        nextId: nextDate ? pageParam + dateDifference : null,
      };
    }
  } catch (error) {
    console.log(error?.response?.data?.message || error || 'Greška...');
    throw error;
  }
};

export default fetchTasks;
