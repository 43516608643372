import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useLocation, Redirect } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, FormHelperText } from '@material-ui/core';
import SmartForm from '../components/rhf/SmartForm';
import Input from '../components/rhf/Input';
import { FullLogo as Logo } from '../components/Logo';
import backgroundImage from '../images/login-bg.svg';
import useFirebaseAuth from '../state/useFirebaseAuth';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-image: url(${backgroundImage});
  height: 100vh;
  width: 100%;
`;

const Content = styled(Box)`
  ${({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 8px;
  padding: 24px 40px;
  max-width: 400px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  & > * {
    flex: 0 0 auto;
    margin: 16px;
    width: 100%;
  }
  ${theme.breakpoints.down('xs')} {
    min-width: unset;
    padding: 16px;
    margin: 8px;
  }
  `}
`;
const loginSchema = yup.object().shape({
  email: yup.string().email('Unesite ispravan email'),
  password: yup.string().required(`Polje ne smije biti prazno`).typeError(`Password polje ne smije biti prazno`),
});

const Login = () => {
  const location = useLocation();
  const { signIn, isAuthReady, user, error } = useFirebaseAuth();
  const { from } = location.state || { from: { pathname: '/' } };

  const methods = useForm({
    defaultValues: {},
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = async (data) => {
    signIn(data.email, data.password);
  };

  if (user && isAuthReady) return <Redirect to={from?.pathname} />;

  return (
    <Container>
      <Content>
        <Box display="flex" justifyContent="center" mb={2}>
          <Logo width={80} />
        </Box>
        <SmartForm methods={methods} onSubmit={onSubmit} spacing={5}>
          <Input label="Email" name="email" type="email" variant="filled" />
          <Input label="Password" name="password" type="password" variant="filled" />
          <Button size="large" color="primary" variant="contained" type="submit" style={{ width: '100%' }}>
            Prijavi se
          </Button>
          <FormHelperText error variant="filled">
            {error}
          </FormHelperText>
        </SmartForm>
      </Content>
    </Container>
  );
};

export default Login;
