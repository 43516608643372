import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { useSnackbar } from 'notistack';
import { v1 } from 'uuid';
import { FiImage as ImageIcon } from 'react-icons/fi';
import { Button, Grid, Box, CircularProgress } from '@material-ui/core';
import fetcher from '../../api/fetcher';
import { useQueryClient } from 'react-query';

const Wrapper = styled(Box)`
  button.MuiButton-contained {
    border-radius: 8px;
  }
`;

const Dropzone = ({ onChange, taskId, files = [] }) => {
  const upload = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const queryClient = useQueryClient();
  const { getInputProps } = useDropzone({
    accept: [
      'image/*',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/docx',
      'application/pdf',
      'text/plain',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ],
    maxSize: 25 * 1024 * 1024,
    onDropRejected: (rejectedFiles) => {
      rejectedFiles.forEach(({ errors }) => {
        errors.forEach(({ code, message }) => {
          if (code === 'file-too-large') {
            enqueueSnackbar('Datoteka je veća od 25MB', { variant: 'error' });
          } else if (code === 'file-invalid-type') {
            enqueueSnackbar('Nepodržan tip dokumenta', { variant: 'error' });
          } else {
            enqueueSnackbar(`Došlo je do greške pri prenosu dokumenta: ${message}`, { variant: 'error' });
          }
        });
      });
    },
    onDropAccepted: async (acceptedFiles) => {
      const filesWithUuid = acceptedFiles.map((file) =>
        Object.assign(file, {
          fileFormat: file.type,
          fileName: file.name,
          uuid: `${v1()}-${file.name}`,
          preview: URL.createObjectURL(file),
          sizeText: `${(file.size / (1024 * 1024)).toFixed(2)}MB`,
        })
      );
      if (filesWithUuid.length) {
        setUploading(true);
        await triggerUpload(filesWithUuid);
      }
    },
  });

  const triggerUpload = async (newFiles) => {
    try {
      await fetcher({
        method: 'POST',
        url: `/documents/upload`,
        data: newFiles.reduce((formData, file) => {
          formData.append('files', file, file.uuid);
          return formData;
        }, new FormData()),
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const documents = await Promise.all(
        newFiles.map(({ path, uuid, type, size, fileName }) =>
          fetcher({
            method: 'POST',
            url: `/documents/create`,
            data: {
              fileName: fileName.substr(0, 100),
              filePath: path,
              uuid,
              task: taskId,
              fileFormat: type,
              fileSize: size,
            },
          })
        )
      );
      // assign id from database
      documents.forEach(({ data: { id, uuid } }) => {
        const f = newFiles.find((file) => file.uuid === uuid);
        if (f) f.id = id;
      });
      setUploading(false);
      queryClient.invalidateQueries(`Images-${taskId}`);
      onChange([...files, ...newFiles]);
    } catch (err) {
      setUploading(false);
      enqueueSnackbar('Greška! Pokušajte ponovo.', {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (uploading) {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            return 0;
          }
          const diff = Math.random() * 10;
          return Math.min(oldProgress + diff, 100);
        });
      }, 500);

      return () => {
        clearInterval(timer);
      };
    }
  }, [uploading]);

  return (
    <Wrapper>
      <Grid item container alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <input {...getInputProps()} ref={upload} />
          <Button
            variant="contained"
            startIcon={uploading ? <CircularProgress size={24} value={progress} color="primary" /> : <ImageIcon />}
            onClick={() => upload.current.click()}
            disabled={uploading}
            fullWidth
          >
            Priložite sliku(e)
          </Button>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default Dropzone;
