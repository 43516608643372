import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import overrides from './overrides';
import typography from './typography';
import palette from './palette';
import breakpoints from './breakpoints';
import shadows from './shadows';

const theme = createMuiTheme({
  overrides,
  spacing: 8,
  typography,
  palette,
  shadows,
  breakpoints,
  props: {
    MuiAppBar: {
      color: 'transparent',
    },
  },
  shape: {
    borderRadius: 4,
  },
  navbarWidth: 68,
  mobileNavbarWidth: '73vw',
  topBarHeight: 48,
  mobileTopBarHeight: 48,
});

export default responsiveFontSizes(theme);
