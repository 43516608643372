import palette from '../palette';

const MuiInputLabel = {
  root: {
    color: palette.text.placeholder,
  },
  shrink: {
    color: `${palette.text.placeholder} !important`,
    fontWeight: 600,
  },
  filled: {
    color: palette.text.placeholder,
    fontWeight: 500,
  },
};

export default MuiInputLabel;
