import React, { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { FiPackage as PackageIcon, FiMessageCircle as MessageIcon, FiTruck as TruckIcon } from 'react-icons/fi';
import { FaBell as BellIcon } from 'react-icons/fa';
import styled from 'styled-components';

const iconsMap = {
  order_placed: PackageIcon,
  new_message: MessageIcon,
  item_shipped: TruckIcon,
};

const NotificationButton = styled(IconButton)`
  height: 32px;
  width: 32px;
  background: ${({ theme }) => theme.palette.background.paper};
  color: #e6e6e6;
  box-shadow: 0 2.9px 4.7px rgba(0, 0, 0, 0.028), 0 8px 12.9px rgba(0, 0, 0, 0.04), 0 19.3px 31.1px rgba(0, 0, 0, 0.052),
    0 64px 103px rgba(0, 0, 0, 0.08);
`;

const PopoverContainer = styled(Popover)`
  width: 320px;
  && > .MuiPaper-root {
    width: 320px;
  }
`;

function Notifications() {
  const notifications = [];
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Notifications">
        <NotificationButton color="inherit" ref={ref} onClick={handleOpen} size="small">
          <BellIcon />
        </NotificationButton>
      </Tooltip>
      <PopoverContainer
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        disableScrollLock
      >
        <Box p={2}>
          <Typography variant="h6" color="textPrimary">
            Notifikacije
          </Typography>
          {notifications.length === 0 ? (
            <Typography variant="body2" color="textSecondary">
              Trenutno nemate novih notifikacija
            </Typography>
          ) : (
            <List disablePadding>
              {notifications.map((notification) => {
                const Icon = iconsMap[notification.type];
                return (
                  <ListItem component={RouterLink} divider key={notification.id} to="#">
                    <ListItemAvatar>
                      <Avatar>
                        <Icon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={notification.title}
                      primaryTypographyProps={{ variant: 'subtitle2', color: 'textPrimary' }}
                      secondary={notification.description}
                    />
                  </ListItem>
                );
              })}
            </List>
          )}
        </Box>
      </PopoverContainer>
    </>
  );
}

export default Notifications;
