import React from 'react';
import { TextField } from '@material-ui/core';
import { Controller } from 'react-hook-form';

const Input = ({ name, control, ...rest }) => {
  return (
    <Controller
      render={({ field }) => <TextField {...field} {...rest} />}
      name={name}
      control={control}
      defaultValue=""
    />
  );
};

export default Input;
