import palette from 'src/theme/palette';

const formatBarDatum = (salesman, id, done, failed, pending) => ({
  salesman,
  id,
  initials: salesman
    .split(' ')
    .reduce(
      (salesman, name, index) =>
        index !== 0
          ? (salesman += `${name[0]}. `)
          : salesman + name[0] + name.slice(1).toLowerCase() + ' ',
      '',
    ),

  'Urađeni zadaci': done,
  urađenizadaciColor: palette.success.main,

  'Zadaci u izradi': pending,
  zadaciuizradiColor: palette.primary.main,

  'Neurađeni zadaci': failed,
  neurađenizadaciColor: palette.error.main,
});

const formatMetrics = (metrics = []) => {
  let pieData = {
    total: 0,
    pieChartData: [
      {
        id: 'Urađeni',
        label: 'Urađeni zadaci',
        value: 0,
        color: palette.success.main,
      },
      {
        id: 'U Izradi',
        label: 'Zadaci u izradi',
        value: 0,
        color: palette.primary.main,
      },
      {
        id: 'Neurađeni',
        label: 'Neurađeni zadaci',
        value: 0,
        color: palette.error.main,
      },
    ],
  };
  const barData = metrics.length > 1 ? [] : null;

  if (metrics.length > 1)
    pieData = metrics.reduce(
      ({ total, pieChartData }, { salesman, id, done, failed, pending }) => {
        const [pieDone, piePending, pieFailed] = pieChartData;
        total += done + failed + pending;

        pieDone.value += done;
        pieFailed.value += failed;
        piePending.value += pending;

        if (done || pending || failed) {
          barData.push(formatBarDatum(salesman, id, done, failed, pending));
        }
        return { total, pieChartData: [pieDone, piePending, pieFailed] };
      },
      { ...pieData },
    );
  else if (metrics.length === 1) {
    const { done, failed, pending, salesman, id: salesmanId } = metrics[0];
    pieData = {
      total: done + failed + pending,
      pieChartData: [
        {
          salesman,
          salesmanId,
          id: 'Urađeni',
          label: 'Urađeni zadaci',
          value: done,
          color: palette.success.main,
        },
        {
          salesman,
          salesmanId,
          id: 'U Izradi',
          label: 'Zadaci u izradi',
          value: pending,
          color: palette.primary.main,
        },
        {
          salesman,
          salesmanId,
          id: 'Neurađeni',
          label: 'Neurađeni zadaci',
          value: failed,
          color: palette.error.main,
        },
      ],
    };
  }

  return { pieData, barData: barData ? barData.reverse() : null };
};

const services = () => ({
  formatMetrics: (metrics) => formatMetrics(metrics),
});

export default services;
