import React from 'react';

import styled from 'styled-components';

import { Box, Drawer, IconButton, Typography } from '@material-ui/core';

import { FaUserAlt } from 'react-icons/fa';
import { IoCloseOutline } from 'react-icons/io5';

import palette from 'src/theme/palette';
import SimpleTaskView from './SimpleTaskView';
import TaskTabs from './task-tabs/TaskTabs';
import LockTask from './LockTask';

const TaskContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  padding: 20px 16px;

  height: 91vh;

  & .task-header > * {
    padding: 0;

    &:first-child {
      padding-left: 5px;
    }
  }

  & .MuiInputBase-root {
    padding: 0;
  }
  & .MuiInputBase-inputMultiline {
    color: ${palette.text.primary};
    font-size: '0.875rem';
    font-weight: 400;
    line-height: 1.5;
  }

  & .salesman-icon {
    margin-right: 8px;
  }
  & .close-icon {
    margin-top: -3px;
  }
`;

const Task = ({
  id,
  customer,
  dueDate,
  name,
  salesman,
  status = false,
  open = false,
  articleCount = 0,
  commentCount = 0,
  imageCount = 0,
  groupIndex = 0,
  salesmenIndex = 0,
  taskIndex = 0,
  close,
  updateStatus,
  updatePriority,
  priority,
  listed,
  canUnlock,
  taskId,
}) => {
  return (
    <Drawer anchor="bottom" open={open} onClose={close}>
      <TaskContainer>
        <Box
          display="flex"
          justifyContent={salesman ? 'space-between' : 'flex-end'}
          onClick={close}
          className="task-header"
        >
          {salesman && (
            <Typography variant="subtitle2">
              <FaUserAlt className="salesman-icon" size={10} />
              {salesman}
            </Typography>
          )}
          <IconButton className="close-icon" onClick={close}>
            <IoCloseOutline size={20} />
          </IconButton>
        </Box>
        <Box>
          <SimpleTaskView
            id={id}
            name={name}
            dueDate={dueDate}
            customer={customer}
            salesman={salesman}
            status={status}
            articleCount={articleCount}
            commentCount={commentCount}
            imageCount={imageCount}
            updateStatus={updateStatus}
            groupIndex={groupIndex}
            salesmenIndex={salesmenIndex}
            taskIndex={taskIndex}
            boundHeight
            priority={priority}
            listed={listed}
          />
        </Box>
        {canUnlock === 'D' && (
          <LockTask
            updatePriority={() =>
              updatePriority({
                priority: priority === 'D' ? 'N' : 'D',
                id,
                groupIndex,
                salesmenIndex,
                taskIndex,
              })
            }
            priority={priority}
            taskId={taskId}
          />
        )}
        <TaskTabs taskId={id} />
      </TaskContainer>
    </Drawer>
  );
};

export default Task;
