import React from 'react';

import moment from 'moment';
import styled from 'styled-components';

import { Box, Typography } from '@material-ui/core';

import { BsListCheck } from 'react-icons/bs';
import { GoComment } from 'react-icons/go';
import { IoImageOutline, IoCalendarClearOutline } from 'react-icons/io5';
import { FiLock } from 'react-icons/fi';
import { IoCartOutline } from 'react-icons/io5';

import palette from 'src/theme/palette';

const TaskIconRowContainer = styled.div`
  display: flex;
  justify-content: space-between;

  & .MuiTypography-subtitle2 {
    text-transform: capitalize;
    margin-right: 0.5rem;

    & svg {
      margin-bottom: -3px;
    }
  }

  & .due-date {
    color: ${({ status }) =>
      status === 'overdue'
        ? palette.error.main
        : status === 'soon'
        ? palette.warning.dark
        : status === 'fine'
        ? palette.success.main
        : palette.background.gray};
  }
`;

const getDueDateStatus = (dueDate) => {
  const dayDifference = moment(dueDate).diff(moment(), 'day');

  if (dayDifference <= 0) return 'overdue';
  else if (dayDifference <= 4) return 'soon';
  else if (dayDifference > 4) return 'fine';
};

const TaskIconRow = ({
  dueDate,
  articleCount,
  commentCount,
  imageCount,
  priority,
  listed,
}) => {
  return (
    <TaskIconRowContainer status={getDueDateStatus(dueDate)}>
      <Box display="flex" justifyContent="flex-start" flex={2}>
        <Typography variant="subtitle2" className="due-date">
          <IoCalendarClearOutline
            size={16} //color={dueDate !== null ? palette.info.main : palette.background.gray}
          />{' '}
          {dueDate !== null && moment(dueDate).format('MMMM DD')}
        </Typography>
        <Typography variant="subtitle2">
          <BsListCheck
            size={16}
            color={
              articleCount !== 0 ? palette.info.main : palette.background.gray
            }
          />{' '}
          {articleCount !== 0 && '0/' + articleCount}
        </Typography>
      </Box>

      <Box display="flex" flex={1} justifyContent="flex-end">
        <Typography variant="subtitle2">
          <FiLock
            size={16}
            color={
              priority === 'D'
                ? palette.secondary.main
                : palette.background.gray
            }
          />
        </Typography>

        <Typography variant="subtitle2">
          <IoCartOutline
            size={16}
            color={
              listed === 'D' ? palette.secondary.main : palette.background.gray
            }
          />
        </Typography>

        <Typography variant="subtitle2">
          {commentCount !== 0 && commentCount}{' '}
          <GoComment
            size={16}
            color={
              commentCount !== 0
                ? palette.secondary.main
                : palette.background.gray
            }
          />
        </Typography>
        <Typography variant="subtitle2">
          {imageCount !== 0 && imageCount}{' '}
          <IoImageOutline
            size={16}
            color={
              imageCount !== 0
                ? palette.secondary.main
                : palette.background.gray
            }
          />
        </Typography>
      </Box>
    </TaskIconRowContainer>
  );
};

export default React.memo(TaskIconRow);
