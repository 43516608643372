import React from 'react';
import styled from 'styled-components';
import { FaUserCircle, FaUsers } from 'react-icons/fa';
import { FiHome, FiList, FiPieChart } from 'react-icons/fi';
import { Box, Drawer } from '@material-ui/core';
import NavItem from './NavItem';

const navConfig = [
  {
    icon: <FiHome />,
    title: 'Pregled',
    href: '/home',
  },
  {
    icon: <FiList />,
    title: 'Zadaci',
    href: '/tasks',
  },
  {
    icon: <FiPieChart />,
    title: 'Analitike',
    href: '/analytics',
  },
  {
    icon: <FaUsers />,
    title: 'Radnici',
    href: '/employees',
  },
  {
    icon: <FaUserCircle />,
    title: 'Profil',
    href: '/profile',
  },
];

const MobileDrawer = styled(Drawer)`
  ${({ theme }) => `      
    && > .MuiPaper-root {
      border-top: 1px solid #e6e6e6;
      align-items: center;
    }
  `}
`;

const NavBar = () => {
  return (
    <MobileDrawer open anchor="bottom" variant="persistent">
      <Box height="100%" width="95%" display="flex" justifyContent="space-between" maxWidth={450}>
        {navConfig.map((config) => (
          <NavItem key={config.title} title={config.title} icon={() => config.icon} href={config.href} />
        ))}
      </Box>
    </MobileDrawer>
  );
};

export default NavBar;
