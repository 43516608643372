import { useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/messaging';
import { useSnackbar } from 'notistack';
//import Notification from '../components/Notification';
import useFirebaseAuth from '../state/useFirebaseAuth';
import { useMutation } from 'react-query';
import subscribeToken from '../api/salesman/subscribeToken';

const useFirebaseMessage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { user, isAuthReady } = useFirebaseAuth();
  const {
    mutate: subscribe,
    // isLoading: isSubscribing
  } = useMutation(subscribeToken);
  useEffect(() => {
    const getToken = (messaging) => {
      return messaging
        .getToken({ vapidKey: process.env.REACT_APP_FIREBASE_VAPIDKEY })
        .then((token) => {
          if (token) {
            // console.log('current token for client: ', token);
            subscribe(token);
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
          } else {
            console.log(
              'No registration token available. Request permission to generate one.',
            );
            // shows on the UI that permission is required
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
          // catch error while creating client token
        });
    };
    const onMessageListener = (messaging) =>
      new Promise((resolve) => {
        messaging.onMessage((payload) => {
          resolve(payload);
        });
      });
    if (firebase.messaging.isSupported() && user && isAuthReady) {
      const messaging = firebase.messaging();
      getToken(messaging);

      setInterval(() => {
        onMessageListener(messaging)
          .then((payload) => {
            enqueueSnackbar(payload?.data?.title, {
              variant: 'default',
              // variant: 'info',
              // content: (key) => <Notification key={key} title={payload?.data?.title} body={payload?.data?.body} />,
            });
          })
          .catch((err) => console.log('failed: ', err));
      }, 250);
    }
  }, [user, isAuthReady, enqueueSnackbar]);
};

export default useFirebaseMessage;
