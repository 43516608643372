import { createMuiTheme } from '@material-ui/core';

const defaultTheme = createMuiTheme();

const MuiButton = {
  root: {
    padding: '8px 16px',
    transition: defaultTheme.transitions.create(['background-color', 'box-shadow', 'border', 'color'], {
      duration: defaultTheme.transitions.duration.short,
    }),
  },
  sizeSmall: {
    padding: '4px 8px',
    fontSize: '0.875rem',
    [defaultTheme.breakpoints.down('xs')]: {
      fontSize: '0.675rem',
      padding: '4px 8px',
    },
  },

  sizeLarge: {
    minWidth: 64,
    minHeight: 48,
    padding: '8px 24px',
    fontSize: '1rem',
  },
  text: {
    fontWeight: 500,
  },
  startIcon: {
    marginRight: '5px',
  },
  endIcon: {
    marginLeft: '5px',
  },
};

export default MuiButton;
