import React from 'react';
import moment from 'moment-timezone';
import localization from 'moment/locale/bs';
import { BrowserRouter as Router } from 'react-router-dom';
import { Normalize } from 'styled-normalize';
import { ThemeProvider } from 'styled-components';
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from 'react-query';
import { MuiThemeProvider, StylesProvider } from '@material-ui/core';
import theme from './theme';
import Routes from './Routes';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '@fontsource/inter';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';

moment.updateLocale('bs', localization);
moment.tz.setDefault('Europe/Vienna');

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <StylesProvider injectFirst>
            <Normalize />
            <SnackbarProvider maxSnack={3} autoHideDuration={5000}>
              <Router basename={process.env.REACT_APP_BASE_PATH}>
                <Routes />
              </Router>
            </SnackbarProvider>
          </StylesProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
