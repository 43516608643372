import React from 'react';
import styled from 'styled-components';
import Lottie from './Lottie';
import loaderData from './animationData/loader.json';

const Container = styled.div`
  && > div {
    margin: auto;
    z-index: 1000;
  }
`;

const Spinner = () => {
  return (
    <Container>
      <Lottie animationData={loaderData} animate={true} maxWidth="55%" />
    </Container>
  );
};

export default Spinner;
