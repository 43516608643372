import { transparentize } from 'polished';
import palette from './palette';

const softShadows = [
  'rgba(149, 157, 165, 0.08) 0px 8px 16px',
  '0 2.9px 4.7px rgba(0, 0, 0, 0.011), 0 8px 12.9px rgba(0, 0, 0, 0.01), 0 19.3px 31.1px rgba(0, 0, 0, 0.018), 0 64px 103px rgba(0, 0, 0, 0.03)',
  'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
  'none',
  'none',
  'none',
  'none',
  'none',
  `${transparentize(0.6, palette.success.main)}  0px 0px 0px 3px`,
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
];

export default softShadows;
