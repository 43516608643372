import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ellipsis } from 'polished';
import { FixedSizeList } from 'react-window';
import memoize from 'memoize-one';

import { Checkbox, Avatar, List, ListItemText, ListItem, ListItemAvatar, Typography, Box } from '@material-ui/core';
import Empty from './lotties/Empty';

const StyledList = styled(List)`
  .MuiListItem-root,
  .MuiListSubheader-root {
    padding-left: 0;
  }
`;

const StyledAvatar = styled(Avatar)`
  height: 32px;
  width: 32px;
  font-size: 16px;
  background-color: ${({ theme, checked }) => (checked ? theme.palette.secondary.main : theme.palette.grey[300])};
`;

const StyledHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;

  & .MuiTypography-overline {
    cursor: pointer;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    h5,
    h6 {
      ${ellipsis('140px')}
    }
  }
`;

const CustomCheckbox = ({ handleCheck, inputProps, name, checked }) => {
  return <Checkbox checked={checked} onChange={handleCheck} edge="end" name={name} inputProps={inputProps} />;
};

const Row = ({ data, index, style }) => {
  const { items, checkItem, checkedItems } = data;
  const { id, name, code, salesman = null } = items[index];
  const labelId = `${id}-${code}-${name}`;

  const inputName = salesman ? salesman + '->' + id : `${id}`;
  const checked = checkedItems[inputName] !== undefined;

  return (
    <ListItem button style={{ ...style, boxSizing: 'border-box', paddingRight: '0', overflow: 'hidden' }}>
      <ListItemAvatar>
        <StyledAvatar checked={checked}>{name[0]}</StyledAvatar>
      </ListItemAvatar>

      <ListItemText id={labelId} primary={`${code} - ${name}`} />

      <Box display="flex" justifyContent="flex-end">
        <CustomCheckbox
          name={inputName}
          handleCheck={checkItem}
          checked={checked}
          inputProps={{ 'aria-labelledby': labelId }}
        />
      </Box>
    </ListItem>
  );
};

const createItemData = memoize((data, checkedItems, checkItem) => ({
  items: data,
  checkedItems,
  checkItem,
}));

const CheckboxListNew = ({ title, data, checkedItems, checkItem, checkAll, isShown = false }) => {
  const itemData = createItemData(data, checkedItems, checkItem);

  const [shown, setShown] = useState(isShown);

  const show = () => setShown(!shown);
  useEffect(() => setShown(isShown), [isShown]);

  return (
    <StyledList
      dense
      disablePadding
      subheader={
        <StyledHeader my={2} pb={1}>
          <Box display="flex">
            <Typography variant="h6">{title}</Typography> <Typography variant="h6">({data.length})</Typography>
          </Box>
          <Box>
            {(isShown === true || shown) && (
              <React.Fragment>
                <Box display="inline-block" pr={2}>
                  <Typography variant="overline" onClick={() => checkAll(true)} color="primary">
                    Odaberi sve
                  </Typography>
                </Box>
                <Typography variant="overline" onClick={() => checkAll(false)}>
                  Očisti
                </Typography>
              </React.Fragment>
            )}

            {!shown && (
              <Typography variant="overline" onClick={show} color="primary">
                Prikaži sve
              </Typography>
            )}
          </Box>
        </StyledHeader>
      }
    >
      {(isShown === true || shown) && data.length !== 0 && (
        <FixedSizeList
          height={data.length * 48 <= 470 ? data.length * 48 : 470}
          itemCount={data.length}
          itemData={itemData}
          itemSize={48}
          width="87vw"
        >
          {Row}
        </FixedSizeList>
      )}

      {(isShown === true || shown) && data.length === 0 && <Empty title="Nema podataka" />}
    </StyledList>
  );
};

const MemoizedCheckboxListNew = React.memo(CheckboxListNew, (prevProps, props) => {
  const prevCheckedItems = Object.keys(prevProps.checkedItems).length;
  const checkedItems = Object.keys(props.checkedItems).length;

  const prevIsShown = prevProps.isShown;
  const isShown = props.isShown;

  const prevDataCount = prevProps.data.length;
  const dataCount = props.data.length;

  return prevCheckedItems === checkedItems && prevIsShown === isShown && prevDataCount === dataCount;
});

export default MemoizedCheckboxListNew;
// export default CheckboxListNew;
