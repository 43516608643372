import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Spinner from './components/lotties/Spinner';
import PrivateRoute from './components/PrivateRoute';

import NotFound from './pages/404';
import Login from './pages/Login';
import useFirebaseNotifications from './hooks/useFirebaseMessage';

import Home from './pages/home';
import Tasks from './pages/tasks';

// const CreateTask = React.lazy(() => import('./pages/createTask'));
const CreateTask = React.lazy(() => import('./pages/createTaskNew'));

const Routes = () => {
  useFirebaseNotifications();
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Redirect exact from="/" to="/home" />
        <Route exact path="/login">
          <Login />
        </Route>
        <PrivateRoute path="/home">
          <Home />
        </PrivateRoute>

        <PrivateRoute path="/tasks">
          <Tasks />
        </PrivateRoute>
        <PrivateRoute path="/create-task">
          <CreateTask />
        </PrivateRoute>
        <PrivateRoute path="*">
          <NotFound />
        </PrivateRoute>
      </Switch>
    </Suspense>
  );
};

export default Routes;
