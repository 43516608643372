import palette from '../palette';

const MuiLink = {
  root: {
    color: palette.text.link,
    fontWeight: 500,
  },
};

export default MuiLink;
