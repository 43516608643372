import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { Box, Grid, Paper, Typography } from '@material-ui/core';

import Layout from 'src/layouts';
import MyPieChart from 'src/components/charts/MyPieChart';
import MyBarChart from 'src/components/charts/MyBarChart';
import fetchTaskMetrics from 'src/api/tasks/fetchTaskMetrics';
import services from './services';
import Spinner from 'src/components/lotties/Spinner';
import ServerError from 'src/components/lotties/ServerError';

const HomePage = () => {
  const { formatMetrics } = services();

  const { data, status, error } = useQuery('metrics', fetchTaskMetrics, {
    refetchOnWindowFocus: false,

    select: (metrics) => {
      if (metrics) return formatMetrics(metrics);
    },
  });

  useEffect(() => window.scrollTo({ top: 0, behavior: 'auto' }), []);

  if (error)
    return (
      <Layout display="flex" justifyContent="space-between">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ServerError error={error} />
          </Grid>
        </Grid>
      </Layout>
    );
  else
    return (
      <Layout display="flex" justifyContent="space-between">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3">Statistika radnih zadataka</Typography>
          </Grid>

          {status === 'loading' && (
            <Grid item xs={12}>
              <Paper elevation={2}>
                <Spinner />
              </Paper>
            </Grid>
          )}

          {status === 'success' && (
            <>
              <Grid item xs={12}>
                <Paper variant="outlined">
                  <Box>
                    <Typography
                      variant="h5"
                      style={{
                        padding: '16px',
                        borderBottom: '1px solid #eee',
                      }}
                    >
                      Generalna statistika
                    </Typography>
                    <Box height={['55vh', '85vh', '40vh']} p={1}>
                      <MyPieChart data={data.pieData} />
                    </Box>
                  </Box>
                </Paper>
              </Grid>

              {data.barData?.length > 1 && (
                <Grid item xs={12}>
                  <Paper variant="outlined">
                    <Box
                      height={`${
                        data.barData.length > 10
                          ? data.barData.length * 7
                          : data.barData.length * 15
                      }vh`}
                      width={1}
                      overflow="hidden"
                    >
                      <Typography
                        variant="h5"
                        style={{
                          padding: '16px',
                          borderBottom: '1px solid #eee',
                        }}
                      >
                        Pojedinačna statistika
                      </Typography>
                      <MyBarChart data={data.barData} />
                    </Box>
                  </Paper>
                </Grid>
              )}
            </>
          )}

          <Grid item xs={12}>
            <Box height="5vh" />
          </Grid>
        </Grid>
      </Layout>
    );
};

export default HomePage;
