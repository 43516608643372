import React, { useState } from 'react';

import { Box, Tab, Tabs } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import CommentsTab from './CommentsTab';
import ProductBrendTab from './ProductBrendTab';
import ImagesTab from './ImagesTab';

const TaskTabs = ({ taskId }) => {
  const [tabIndex, setTabIndex] = useState(1);
  const handleChange = (_, index) => setTabIndex(index);
  const handleChangeIndex = (index) => {
    setTabIndex(index);
  };

  return (
    <Box flex={2}>
      <Tabs value={tabIndex} onChange={handleChange} indicatorColor="primary" variant="fullWidth">
        <Tab label="Stavke" id={0} />
        <Tab label="Komentari" id={1} />
        <Tab label="Slike" id={2} />
      </Tabs>

      <Box pb={1}>
        <SwipeableViews axis="x" index={tabIndex} onChangeIndex={handleChangeIndex}>
          <ProductBrendTab taskId={taskId} />
          <CommentsTab taskId={taskId} hidden={tabIndex !== 1} />
          <ImagesTab taskId={taskId} hidden={tabIndex !== 2} />
        </SwipeableViews>
      </Box>
    </Box>
  );
};

export default TaskTabs;
