import moment from 'moment-timezone';

import groupBy from 'src/utils/groupBy';

const formatFilterParameters = (params) => {
  const { salesmen, customers, fromDate, untilDate, taskStatus } = params;

  const formatedSalesmen = salesmen.map(({ value }) => value);
  const formatedCustomers = customers.map(({ value }) => value);

  return {
    salesmen: formatedSalesmen,
    customers: formatedCustomers,

    fromDate: fromDate || null,
    untilDate: untilDate || moment().format('yyyy-MM-DD'),
    taskStatus,
  };
};

// const concatDataPages = (data) => {
//   const tasks = data?.pages?.reduce(
//     (final, { groupedTasks }) => (final = final.concat(groupedTasks)),
//     [],
//   );

//   return tasks;
// };

const formatResults = (tasks) => {
  const groupedByCreateDate = groupBy(tasks, 'startDate');
  let numberOfTasks = 0;

  const groupedByCreateDateAndSalesmen = Object.entries(
    groupedByCreateDate,
  ).map(([date, tasks]) => ({
    startDate: moment(date).format('ddd DD.MM').replace('.', ''),
    salesmen: Object.entries(groupBy(tasks, 'salesman')).map(
      ([salesman, tasks]) => {
        numberOfTasks += tasks.length;
        return {
          salesman,
          tasks,
        };
      },
    ),
  }));

  return { numberOfTasks, groupedTasks: groupedByCreateDateAndSalesmen };
};

const updateTaskStatus = async (
  setState,
  mutateTaskStatus,
  taskToUpdate,
  isFiltering,
) => {
  const { id, groupIndex, salesmenIndex, taskIndex, status } = taskToUpdate;

  const taskUpdated = await mutateTaskStatus({ taskId: id, status });

  if (taskUpdated)
    setState((state) => {
      const {
        shownTask,
        tasks,
        filter: { tasks: filteredTasks },
      } = state;
      const newStatus = status === true ? true : null;

      if (!isFiltering)
        tasks[groupIndex].salesmen[salesmenIndex].tasks[taskIndex].status =
          newStatus;
      else
        filteredTasks[groupIndex].salesmen[salesmenIndex].tasks[
          taskIndex
        ].status = newStatus;

      if (shownTask) shownTask.status = newStatus;

      if (!isFiltering) return { ...state, tasks, shownTask };
      else
        return {
          ...state,
          shownTask,
          filter: { ...state.filter, filteredTasks },
        };
    });
};
const updateTaskPriority = async (
  setState,
  mutateTaskPriority,
  taskToUpdate,
  isFiltering,
) => {
  const { id, groupIndex, salesmenIndex, taskIndex, priority } = taskToUpdate;

  const priorityUpdated = await mutateTaskPriority({
    taskId: id,
    priority: priority,
  });

  if (priorityUpdated)
    setState((state) => {
      const {
        shownTask,
        tasks,
        filter: { tasks: filteredTasks },
      } = state;

      if (!isFiltering)
        tasks[groupIndex].salesmen[salesmenIndex].tasks[taskIndex].priority =
          priority;
      else
        filteredTasks[groupIndex].salesmen[salesmenIndex].tasks[
          taskIndex
        ].priority = priority;

      shownTask.priority = priority;

      if (!isFiltering) return { ...state, tasks, shownTask };
      else
        return {
          ...state,
          shownTask,
          filter: { ...state.filter, filteredTasks },
        };
    });
};

const toggleTask = (setState, shownTask) =>
  setState((state) => ({ ...state, shownTask }));

const updateTasks = (setState, { groupedTasks, numberOfTasks }) =>
  setState((state) => ({
    ...state,
    tasks: [...state.tasks, ...groupedTasks],
    numberOfTasks: state.numberOfTasks + numberOfTasks,
  }));

const updateFilteredTasks = (setState, numberOfTasks, tasks) =>
  setState((state) => ({
    ...state,
    tasks: [],
    numberOfTasks: 0,
    filter: { ...state.filter, shown: false, numberOfTasks, tasks },
  }));

const updateAppliedFilters = (setState) =>
  setState((state) => {
    const { taskStatus, fromDate, untilDate, salesmen, customers } =
      state.filter.params;
    let appliedFilters = 0;

    if (taskStatus !== 1) appliedFilters += 1;
    if (fromDate !== '') appliedFilters += 1;
    if (untilDate !== moment().format('yyyy-MM-DD')) appliedFilters += 1;

    if (salesmen.length !== 0) appliedFilters += 1;
    if (customers.length !== 0) appliedFilters += 1;

    return { ...state, filter: { ...state.filter, appliedFilters } };
  });

const pickDate = (setState, name, value) =>
  setState((state) => ({
    ...state,
    filter: {
      ...state.filter,
      params: {
        ...state.filter.params,
        [name]: value,
      },
    },
  }));

const pickStatus = (setState, name, value) =>
  setState((state) => ({
    ...state,
    filter: {
      ...state.filter,
      params: {
        ...state.filter.params,
        [name]: parseInt(value),
      },
    },
  }));

const updateSearchValue = (setState, name, value, action) => {
  action === 'input-change' &&
    setState((state) => ({
      ...state,
      filter: {
        ...state.filter,
        params: {
          ...state.filter.params,
          [name.concat('SearchValue')]: value,
        },
      },
    }));
  return value;
};

const selectOption = (setState, option, action) =>
  setState((state) => {
    let customers = state.filter.params.customers;
    if (action.name === 'salesmen' && action.action === 'remove-value')
      customers = customers.filter(
        ({ salesman }) => salesman !== action.removedValue.value,
      );

    return {
      ...state,
      filter: {
        ...state.filter,
        params: {
          ...state.filter.params,
          customers,
          [action.name]: [...option],
        },
      },
    };
  });

const clearFilter = (setState) =>
  setState((state) => ({
    ...state,
    filter: {
      tasks: null,
      numberOfTasks: null,
      shown: false,
      appliedFilters: 0,
      params: {
        taskStatus: 1,
        fromDate: '',
        untilDate: moment().format('yyyy-MM-DD'),

        salesmen: [],
        customers: [],

        salesmenSearchValue: '',
        customersSearchValue: '',
      },
    },
  }));

const services = (setState, mutateTaskStatus, mutateTaskPriority) => ({
  // concatDataPages: (data) => concatDataPages(data),

  formatFilterParameters: (params) => formatFilterParameters(params),
  formatResults: (tasks) => formatResults(tasks),

  updateTaskStatus: (taskToUpdate, isFiltering = false) =>
    updateTaskStatus(setState, mutateTaskStatus, taskToUpdate, isFiltering),
  updateTaskPriority: (taskToUpdate, isFiltering) =>
    updateTaskPriority(setState, mutateTaskPriority, taskToUpdate, isFiltering),

  toggleTask: (shownTask = null) => toggleTask(setState, shownTask),

  // updateFilterParams: (params) => updateFilterParams(setState, params),
  updateAppliedFilters: () => updateAppliedFilters(setState),
  updateFilteredTasks: ({ numberOfTasks, groupedTasks }) =>
    updateFilteredTasks(setState, numberOfTasks, groupedTasks),
  updateTasks: (tasks) => updateTasks(setState, tasks),

  filterHandlers: {
    pickDate: ({ target: { name, value } }) => pickDate(setState, name, value),

    pickStatus: ({ target: { name, value } }) =>
      pickStatus(setState, name, value),

    updateSearchValue: (searchValue, action, name) =>
      updateSearchValue(setState, name, searchValue, action),
    selectOption: (option, action) => selectOption(setState, option, action),

    clearFilter: () => clearFilter(setState),
  },
});

export default services;
